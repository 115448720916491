import request from '@/utils/request'

// 列表-个人注册账号管理
export const getUserList = (params) => {
  return request
    .get('/api/plat/employmentEmploy/platUserManagementController/queryAllAppUserInfoByCondition', {
      params: {
        ...params
      }
    })
    .then((response) => response)
}

// 列表-企业注册账号管理
export const getEnterpriseList = (params) => {
  return request
    .get('/api/plat/employmentEmploy/platUserManagementController/queryAllEnterpriseUserInfoByCondition', {
      params: {
        ...params
      }
    })
    .then((response) => response)
}

// 列表-供应商注册账号管理
export const getSupplierList = (params) => {
  return request
    .get('/api/plat/employmentEmploy/platUserManagementController/queryAllSupplierUserInfoByCondition', {
      params: {
        ...params
      }
    })
    .then((response) => response)
}

// 列表-用户禁用
export const updateDisabled = (userId) => {
  return request
    .put('/api/plat/employmentEmploy/platUserManagementController/disEnabled', null, {
      params: {
        userId
      }
    })
    .then((response) => response)
}

// 列表-用户恢复
export const updateEnabled = (userId) => {
  return request
    .put('/api/plat/employmentEmploy/platUserManagementController/enabled', null, {
      params: {
        userId
      }
    })
    .then((response) => response)
}

// 列表-企业推荐
export const setRecommended = (model) => {
  return request
    .put('/api/plat/employmentEmploy/platUserManagementController/setRecommended', model)
    .then((response) => response)
}

// 列表-企业用工经历
export const getEmployment = (userId) => {
  return request
    .get('/api/plat/employmentEmploy/platUserManagementController/queryAllProjectByUserId', {
      params: {
        userId
      }
    })
    .then((response) => response)
}

// 列表-下拉框-省
export const getProvinceList = () => {
  return request
    .get('/api/plat/employmentEmploy/platUserManagementController/queryAllProvince')
    .then((response) => response)
}

// 列表-下拉框-市
export const getCityList = (pid) => {
  return request.get(`/api/plat/employmentEmploy/platUserManagementController/${pid}`).then((response) => response)
}

// 用户详情
export const getUserInfo = (userId) => {
  return request
    .get('/api/plat/employmentEmploy/platUserManagementController/queryPUserInfo', {
      params: {
        userId
      }
    })
    .then((response) => response)
}

// 用户详情-用工经历
export const getUserProjectInfo = (params) => {
  return request
    .get('/api/plat/employmentEmploy/platUserManagementController/queryProjectExperienceByUserId', {
      params: {
        ...params
      }
    })
    .then((response) => response)
}

// 企业详情
export const getEnterpriseInfo = (enterpriseId) => {
  return request
    .get('/api/back/employmentEmploy/enterprise_info/queryEnterpriseInfoByEnterpriseId', {
      params: {
        enterpriseId
      }
    })
    .then((response) => response)
}

// 企业详情-用工经历
export const getEnterPriseProjectInfo = (params) => {
  return request
    .get('/api/back/employmentEmploy/project/queryAllProjectByEnterpriseId', {
      params: {
        ...params
      }
    })
    .then((response) => response)
}

// 查询所有的会员类型
export function queryListByCondition () {
  return request({
    url: '/api/member/MemberTypeController/queryListByCondition',
    method: 'get',
    params: {
      status: 1
    }
  })
}

// 查询所有的会员类型
export function userMemberController (data) {
  return request({
    url: '/api/member/UserMemberController',
    method: 'post',
    data
  })
}

// 企业详情-会员信息
export const getEnterPriseVip = (userId) => {
  return request.get(`/api/member/UserMemberController/queryEnterpriseMember/${userId}`).then((response) => response)
}

// 企业详情-用户会员信息
export const getUserVip = (userId) => {
  return request.get(`/api/member/UserMemberController/queryByUserId/${userId}`).then((response) => response)
}

// 供应商详情
export const getSupplierInfo = (userId) => {
  return request
    .get('/api/plat/employmentEmploy/platUserManagementController/querySupplierInfoByUserId', {
      params: {
        userId
      }
    })
    .then((response) => response)
}

// 通过认证
export const pass = (enterpriseId) => {
  return request
    .post('/api/plat/employmentEmploy/platUserManagementController/pass', null, {
      params: {
        enterpriseId
      }
    })
    .then((response) => response)
}

// 通过认证
export const reject = (enterpriseId, message) => {
  return request
    .post('/api/plat/employmentEmploy/platUserManagementController/reject', null, {
      params: {
        enterpriseId,
        message
      }
    })
    .then((response) => response)
}
