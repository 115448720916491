<template>
  <a-card class="card__wrapper" title="App账号管理">
    <a-form-model
      :model="form"
      @submit.native.prevent
      labelAlign="left"
      :label-col="{
        span: 5,
      }"
      :wrapper-col="{
        span: 18,
      }"
    >
      <a-row>
        <a-col :span="6" v-if="isPlat">
          <a-form-model-item label="所属区域" :model="form">
            <a-row>
              <a-col :span="11">
                <a-select placeholder="请选择省" @change="handleGetCityList" v-model="form.provinceId" allowClear>
                  <a-select-option v-for="item in provinceList" :key="item.id" :value="item.id">{{
                    item.district
                  }}</a-select-option>
                </a-select>
              </a-col>
              <a-col :span="11" :offset="2">
                <a-select placeholder="请选择市" v-model="form.cityId" allowClear>
                  <a-select-option v-for="item in cityList" :key="item.id" :value="item.id">{{
                    item.district
                  }}</a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="用户状态">
            <a-select placeholder="请选择" v-model="form.jobStatus" allowClear>
              <a-select-option value="1">忙</a-select-option>
              <a-select-option value="0">闲</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <!-- <a-col :span="6">
            <a-form-model-item label="是否会员">
              <a-select placeholder="请选择" allowClear v-model="form.vipStatus">
                <a-select-option value="1">是</a-select-option>
                <a-select-option value="2">否</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->
        <a-col :span="6">
          <a-form-model-item label="实名状态">
            <a-select placeholder="请选择" allowClear v-model="form.authenticationStatus">
              <a-select-option value="1">已实名</a-select-option>
              <a-select-option value="0">未实名</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="姓名">
            <a-input v-model="form.realName" placeholder="姓名" allowClear />
          </a-form-model-item>
        </a-col>
        <a-col :span="6">
          <a-form-model-item label="手机号">
            <a-input v-model="form.phone" placeholder="手机号" allowClear />
          </a-form-model-item>
        </a-col>
        <a-col :span="isPlat ? 18 : 24" style="text-align: right" v-perms="'search'">
          <a-button type @click="handleReset">重置</a-button>
          <a-button type="primary" @click="handleSearch">搜索</a-button>
        </a-col>
      </a-row>
    </a-form-model>
    <a-row>
      <p-table
        row-key="userId"
        ref="table"
        extraHeight="500"
        :scroll="{ y: 0 }"
        :columns="cols"
        download
        fileName="App账号管理"
        :sourceData="handleGetData"
      >
        <template slot="headerLeft">
          <div style="display: flex; align-items: center">
            <a-button type="primary" @click="handleAdd" v-perms="'add'">新增</a-button>
            <a-upload
              name="file"
              style="margin-left: 8px"
              :showUploadList="false"
              :customRequest="customRequest"
              v-perms="'upload-all'"
            >
              <a-button type="primary">批量导入</a-button>
            </a-upload>

            <a-button
              type="primary"
              style="margin-left: 8px"
              @click="handleDown"
              v-perms="'tem-download'"
            >模板下载</a-button
            >
          </div>
        </template>
        <!-- <template slot="realName" slot-scope="text, record">
          <span class="name__button" @click="handleUserInfo(record)">{{ text }}</span>
        </template> -->
        <template slot="createTime" slot-scope="text">{{ formatTime(text) }}</template>
      </p-table>
    </a-row>
    <addUserModal ref="addUserModal" @success="$refs.table.initPage()" />
  </a-card>
</template>

<script>
import moment from 'moment'
import { getUserList, updateDisabled, updateEnabled, getProvinceList, getCityList } from '@/api/app-users'
import { appParseExcel } from '@/api/system'
import addUserModal from './add-user-modal.vue'

const cols = [
  {
    title: '序号',
    width: 60,
    customRender: (t, r, i) => i + 1
  },
  {
    title: '姓名',
    key: 'realName',
    download: true,
    dataIndex: 'realName',
    scopedSlots: { customRender: 'realName' }
  },
  {
    title: '账号',
    key: 'username',
    download: true,
    dataIndex: 'username'
  },
  {
    title: '手机号',
    key: 'phone',
    download: true,
    dataIndex: 'phone'
  },
  {
    title: '年龄',
    key: 'age',
    download: true,
    dataIndex: 'age'
  },
  {
    title: '性别',
    key: 'sex',
    download: true,
    dataIndex: 'sex',
    customRender: (text) => (text == 1 ? '女' : '男')
  },
  {
    title: '所属区域',
    download: true,
    key: 'location',
    dataIndex: 'location'
  },
  // {
  //   title: '微信ID',
  //   key: 'weChat',
  //   dataIndex: 'weChat'
  // },
  // {
  //   title: '支付宝ID',
  //   key: 'zhiFuBao',
  //   dataIndex: 'zhiFuBao'
  // },
  {
    title: '所属经纪人',
    download: true,
    key: 'agent',
    dataIndex: 'agent'
  },
  {
    title: '注册时间',
    download: true,
    key: 'createTime',
    dataIndex: 'createTime',
    width: 180,
    sorter: true
  },
  {
    title: '实名状态',
    download: true,
    key: 'authenticationStatus',
    dataIndex: 'authenticationStatus',
    customRender: (text) => (text != 1 ? '未实名' : '已实名')
  },
  {
    title: '是否签署承揽协议',
    key: 'signStatus',
    download: true,
    width: 180,
    dataIndex: 'signStatus',
    customRender: (text) => (text == 0 ? '否' : '是')
  },
  {
    title: '状态',
    download: true,
    key: 'jobStatus',
    dataIndex: 'jobStatus',
    customRender: (text) => (text == 0 ? '闲' : '忙')
  },
  {
    title: '会员等级',
    download: true,
    key: 'memberDto',
    dataIndex: 'memberDto',
    customRender: (text) => text.memberTypeDto?.typeName
  }
]

export default {
  name: 'AppUsers',
  components: {
    addUserModal
  },
  data () {
    return {
      cols,
      form: {},
      provinceList: [],
      cityList: []
    }
  },
  created () {
    this.init()
  },
  computed: {
    isPlat () {
      return true
    }
  },
  methods: {
    init () {
      this.handleProvince()
    },
    handleProvince () {
      getProvinceList().then((res) => {
        this.provinceList = res
      })
    },
    handleGetCityList (value) {
      this.cityList = []
      this.$set(this.form, 'cityId', '')
      if (value) {
        getCityList(value).then((res) => {
          this.cityList = res
        })
      }
    },
    fromatChanel (value) {
      const data = this.chanelList.find((item) => item.key == value) || {}
      return data.label
    },
    formatTime (time) {
      return time && moment(time).format('YYYY-MM-DD HH:mm:ss')
    },
    handleReset () {
      this.$set(this, 'form', {})
    },
    handleSearch () {
      this.$refs.table.initPage()
    },
    getQuery () {
      const { realName, phone, vipStatus, jobStatus, authenticationStatus, provinceId, cityId } = this.form
      const provinceName = (this.provinceList.find((item) => item.id === provinceId) || {}).district || ''
      const cityName = cityId ? this.cityList.find((item) => item.id === cityId).district : ''
      const location = this.isPlat
        ? cityName
          ? `${provinceName}-${cityName}`
          : provinceName
        : this.$store.getters.userInfo.location
      return {
        realName,
        phone,
        vipStatus,
        jobStatus,
        authenticationStatus,
        location
      }
    },
    handleGetData (params) {
      const query = this.getQuery()
      const { columnKey: orderField, order: orderType, pageIndex, pageSize } = params
      return getUserList({
        ...query,
        pageIndex,
        pageSize,
        orderField,
        orderType: orderType ? (orderType === 'ascend' ? 'DESC' : 'ASC') : '',
        enterpriseId: this.$store.getters.userInfo.enterpriseInfoDto.enterpriseId
      }).then((res) => {
        return Promise.resolve({
          ...res,
          data: res.data.map((item) => ({
            ...item,
            isEnabled: item.enabled == 1
          }))
        })
      })
    },
    onForbiddenChange (row, index) {
      const text = row.enabled == 0 ? '启用' : '禁用'
      const fun = row.enabled == 0 ? updateEnabled : updateDisabled
      this.$confirm({
        title: '提示',
        content: `是否确认${text}该账号`,
        onOk: () => {
          fun(row.userId).then(() => {
            this.$message.success(`账号${text}成功`)
            this.$refs.table.refresh()
          })
        },
        onCancel: () => {
          this.$set(this.$refs.table.tableData, index, { ...row, isEnabled: !row.isEnabled })
        }
      })
    },
    customRequest (file) {
      this.$spin.show()
      const form = new FormData()
      form.append('file', file.file)
      appParseExcel(form)
        .then((res) => {
          this.$refs.addUserModal.open(res.data)
        })
        .finally(() => {
          this.$spin.hide()
        })
    },
    handleDown () {
      // window.open('/xlsx/施工宝会员为雇员批量注册有好活账号导入表.xlsx')
      this.$spin.show()
      try {
        var a = document.createElement('a') // 创建一个<a />标签
        a.href = '/xlsx/施工宝会员为雇员批量注册有好活账号导入表.xlsx' // 设置下载文件地址 注意：使用英文
        a.download = '施工宝会员为雇员批量注册有好活账号导入表.xlsx' // 设置下载文件文件名
        a.style.display = 'none' // 隐藏a标签
        document.body.appendChild(a) // 将a标签追加到文档对象中
        a.click() // 点击，浏览器自动下载
        a.remove()
      } catch (error) {
      }
      this.$spin.hide()
    },
    handleAdd (row) {
      this.$router.push({
        path: '/system/add-app-users',
        query: {
          id: row.userId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.personal-regedit {
  padding: 40px;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  background: #fff;
}
</style>
